import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import OsintAxios from "@/axios/osint.js";
import {email} from "vuelidate/lib/validators";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
import MultiselectSlot from "@/components/multiselect-template";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import _, {cloneDeep} from "lodash";
import JsonCSV from "vue-json-csv";
import PersonListExpand from "@/components/result-expanded";
import PersonList from "@/components/result-list";
import toolsHelper from "@/mixins/toolsHelper";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import neoSwitch from "@/components/toggle-switch";
import {EventBus} from "@/main.js";
import axios from "@/axios";
import NeoDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import TabComp from "@/components/tab";

export default {
    name: "person-identifier",
    mixins: [toolsHelper],
    components: {
        AnalysisCard,
        NeoLoader,
        NeoInput,
        NeoButton,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        MultiselectSlot,
        NeoAnalyse,
        "download-csv": JsonCSV,
        PersonList,
        PersonListExpand,
        neoSwitch,
        NeoDatePicker,
        "neo-tab": TabComp,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: Array,
        entityValue: String,
    },
    data() {
        return {
            formData: {
                names: [
                    {
                        fNameInput: "",
                        lNameInput: "",
                        mNameInput: "",
                        expanded: false,
                    },
                ],
                locations: [
                    {
                        countryCodeInput: "",
                        stateCodeInput: "",
                        cityInput: "",
                        expanded: false,
                    },
                ],
                contacts: [
                    {
                        emailInput: [],
                        phoneInput: [],
                        expanded: false,
                    },
                ],
                employments: [
                    {
                        titleInput: "",
                        organisationInput: "",
                        industryInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: false,
                    },
                ],
                educations: [
                    {
                        degreeInput: "",
                        schoolInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: false,
                    },
                ],
                socialProfiles: [
                    {
                        platformInput: "",
                        usernameInput: "",
                        expanded: false,
                    },
                ],
            },
            selectedItem: {
                email: [],
                phone: [],
                employment: [],
                education: [],
                platform: [],
                username: [],
            },
            platformList: [],
            usernameList: [],
            phoneList: [],
            emailList: [],
            employmentList: [],
            educationList: [],
            locationList: [],
            allData: [],
            case_id: "",
            isLoading: false,
            csvData: {
                person: [],
            },
            id: 0,
            triggerRoute: true,
            selected_value: {},
            showMoreInfo: false,
            selected_index: null,
            searchClicked: false,
            tabName: "",
            request_id: null,
            tabs: [
                {
                    key: "runCheck",
                    name: "Run Check",
                    enabled: true,
                    readOnly: false,
                    disabled: false,
                },
                {
                    key: "output",
                    name: "Output",
                    enabled: true,
                    readOnly: false,
                    disabled: false,
                },
            ],
            activeTab: "runCheck",
            allCategoriesExpanded: false,
            queriesSections: [
                {
                    label: "Name",
                    value: "names",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Contact",
                    value: "contacts",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Employment",
                    value: "employments",
                    count: 1,
                    maxLimit: 2,
                },
                {
                    label: "Education",
                    value: "educations",
                    count: 1,
                    maxLimit: 2,
                },
                {
                    label: "Location",
                    value: "locations",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Social Detail",
                    value: "socialProfiles",
                    count: 1,
                    maxLimit: 2,
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getCasePersonalData", "getCompanyProducts", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames", "getCaseEmails", "getCasePhones", "getMonitoringDateFilter", "getSelectedToolQuery", "getSocialPlatforms"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        personIdentifierData() {
            return this.allData.map((el, i) => ({
                ...el,
                curr_index: i,
            }));
        },

        csvFields() {
            return ["names", "images", "origin_countries", "languages", "@match", "emails", "phones", "jobs", "educations", "usernames", "user_ids", "relationships", "urls"];
        },
        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },
        checkMarkAllDone() {
            if (this.allData && this.allData.length) return this.allData.every((result) => result.changes_viewed);
            else return false;
        },

        product_data() {
            return this.getCompanyProducts.find((prod) => prod.name === this.getCasePersonalData?.data?.["Due Diligence Level"]?.value);
        },

        isPayloadEmpty() {
            let flag = true;

            if (this.formData?.["contacts"]?.[0]?.emailInput?.length || this.formData?.["contacts"]?.[0]?.phoneInput?.length) {
                flag = false;
                return flag;
            }
            for (const key in this.formData) {
                if (key !== "contacts")
                    this.formData[key].forEach((i) => {
                        for (const field in i) {
                            if (typeof i[field] === "string" && i[field].trim() && !["fromDateInput", "toDateInput", "expanded"].includes(field)) {
                                flag = false;
                                return flag;
                            }
                        }
                    });
            }
            return flag;
        },

        payloadData() {
            console.log(this.formData);
            const payload = {
                name: this.formData.names?.map((i) => ({
                    first_name: i.fNameInput,
                    middle_name: i.mNameInput,
                    last_name: i.lNameInput,
                    name_selected: i.fNameInput || i.mNameInput || i.lNameInput ? true : false,
                })),
                email: this.formData.contacts?.[0]?.emailInput?.map((i) => ({
                    email: i,
                })),
                phone: this.formData.contacts?.[0]?.phoneInput?.map((i) => ({
                    phone: i,
                })),
                jobs: this.formData.employments?.map((i) => ({
                    title: i.titleInput,
                    organization: i.organisationInput,
                    industry: i.industryInput,
                    date_range: {
                        start_date: i.fromDateInput,
                        end_date: i.toDateInput,
                    },
                    job_selected: i.titleInput || i.organisationInput || i.industryInput || i.fromDateInput || i.toDateInput ? true : false,
                })),
                educations: this.formData.educations?.map((i) => ({
                    degree: i.degreeInput,
                    school: i.schoolInput,
                    date_range: {
                        start_date: i.fromDateInput,
                        end_date: i.toDateInput,
                    },
                    education_selected: i.degreeInput || i.schoolInput || i.fromDateInput || i.toDateInput ? true : false,
                })),
                location: this.formData.locations?.map((i) => ({
                    country: i.countryCodeInput,
                    state: i.stateCodeInput,
                    city: i.cityInput,
                    location_selected: i.countryCodeInput || i.stateCodeInput || i.cityInput ? true : false,
                })),
                social_details: this.formData.socialProfiles?.map((i) => ({
                    social_profile: i.platformInput,
                    username: i.usernameInput,
                    social_detail_selected: i.platformInput || i.usernameInput ? true : false,
                })),
                contact_selected: false,
                gender_selected: false,
                top_match: this.product_data.automated ? true : false,
            };

            if (this.formData.contacts?.some((i) => i.emailInput.length)) {
                if (payload.email?.some((i) => i.email)) {
                    payload.contact_selected = true;
                    return payload;
                }
            }

            if (this.formData.contacts?.some((i) => i.phoneInput.length)) {
                if (payload.phone?.some((i) => i.phone)) {
                    payload.contact_selected = true;
                    return payload;
                }
            }
            return payload;
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "person-identifier-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "person-identifier-tool");
        EventBus.$off("handleDone");
    },
    watch: {
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },
    },
    validations: {
        emailInput: {
            email,
        },
    },
    created() {
        EventBus.$on("handleDone", (data) => {
            let result = cloneDeep(this.allData);
            result[data.index] = data.card;
            this.allData = result;
            this.$forceUpdate();
        });
    },
    methods: {
        monitoringSearchData,
        getBackgroundStatus,
        getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseUsernames", "getAllCaseEmails", "getAllCasePhones", "getAllCaseEducations", "getAllCaseEmployments", "getAllCaseLocations"]),
        handleTabChange(event) {
            this.activeTab = event;
        },
        async getToolData() {
            this.phoneList = await this.getDropdownData("phone");
            this.emailList = await this.getDropdownData("email");
            this.educationList = await this.getDropdownData("education");
            this.employmentList = await this.getDropdownData("employment");
            this.locationList = await this.getDropdownData("location");
            this.usernameList = await this.getDropdownData("username");
            this.platformList = this.getSocialPlatforms.map((el) => ({
                ...el,
                value: el.name,
            }));
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            let allUsers = [...this.usernameList, ...this.phoneList, ...this.emailList, ...this.educationList, ...this.employmentList, ...this.locationList];
            this.$emit("updateToolData", "person-identifier", allUsers, data);
            allChecked = allUsers?.length ? (allUsers.findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        async monitoringSearch() {
            let sources = ["person-identifier"];

            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.activeTab = "output";
            this.allData = values[0]?.person ? (this.allData = [values[0]?.person]) : (this.allData = values);
            if (this.allData === null || this.allData === undefined) {
                this.allData = [];
            }
            this.csvData.person = this.convertToCSV(this.personIdentifierData);
        },

        manualSearch() {
            this.triggerRoute = false;
            this.handleSearch();
        },

        async handleSearch() {
            this.searchClicked = true;
            this.allData = [];
            this.closeMoreInfoExpand();
            if (!this.$v.$invalid) {
                if (!this.isPayloadEmpty) {
                    for (const i in this.payloadData.social_details) {
                        if (this.payloadData.social_details[i].social_profile && !this.payloadData.social_details[i].username) {
                            this.$toast.error("Please enter username for the social profile");
                            return;
                        }
                    }

                    this.isLoading = true;
                    this.activeTab = "runCheck";
                    this.$emit("searched", {data: this.payloadData, source: "personIdentifier"});

                    if (this.isMonitoringTab) {
                        this.monitoringSearch();
                        return;
                    }

                    const searchSource = await OsintAxios.post("/person-identifier/search-sources", this.payloadData, {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    });

                    this.request_id = searchSource.data.request_id;
                    const {data} = await OsintAxios.post(
                        "/person-identifier/adv",
                        {
                            ...this.payloadData,
                            request_id: this.request_id,
                        },
                        {
                            headers: {
                                "x-tool-name": this.$route.params.toolName,
                                "x-visited": true,
                            },
                        }
                    );
                    this.isLoading = false;
                    this.runToolsProfiles();
                    if (data.status && (data.data?.person || data.data?.possible_persons)) {
                        data.data?.person ? (this.allData = [data.data?.person]) : (this.allData = data.data?.possible_persons);
                        if (this.allData === null || this.allData === undefined) {
                            this.allData = [];
                        }
                        this.$emit("searchResult", {
                            data: this.allData,
                            source: "personIdentifier",
                            searchType: "personIdentifier",
                        });
                        this.csvData.person = this.convertToCSV(this.personIdentifierData);
                        this.activeTab = "output";
                        this.selected_index = null;
                        this.showMoreInfo = false;
                        this.selected_value = {};
                    } else if (data.status) {
                        this.$toast.error("No data found");
                    } else {
                        this.$toast.error(data.message);
                    }
                    this.activeTab = "output";
                    await this.redirectTo();
                    // }
                    this.triggerRoute = true;
                } else this.$toast.error("Atleast one input is required");
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async fetchMoreData(piData, pi_index) {
            if (!piData.all_data) {
                piData.loading = true;
                this.$forceUpdate();
                const {data} = await OsintAxios.post(
                    "/person-identifier/more",
                    {
                        search_pointer: piData["@search_pointer"],
                    },
                    {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-visited": true,
                        },
                    }
                );
                if (data?.data?.person) {
                    this.allData[pi_index] = {
                        ...data.data.person,
                        loading: false,
                        expanded: true,
                        all_data: true,
                    };
                } else {
                    piData.loading = false;
                    piData.allData = true;
                }
                this.$forceUpdate();
            }
        },

        handleExpand(piData, pi_index) {
            piData.expanded = !piData.expanded;
            this.$forceUpdate();
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                if (Array.isArray(selected_query.name)) {
                    const {email, phone, name, location, social_details, educations, jobs} = selected_query;
                    if (email || phone || name || location || social_details || educations || jobs) {
                        if (email) {
                            const data = email.map((el) => el.email);
                            this.formData.contacts[0] = {
                                ...this.formData?.contacts?.[0],
                                emailInput: data,
                            };
                            this.selectedItem["email"] = data.map((el) => this.getTag(el));
                        }
                        if (phone) {
                            const data = phone.map((el) => el.phone);
                            this.formData.contacts[0] = {
                                ...this.formData?.contacts?.[0],
                                phoneInput: data,
                            };
                            this.selectedItem["phone"] = data.map((el) => this.getTag(el));
                        }
                        if (name) {
                            for (const i in name) {
                                this.formData.names[i] = {
                                    ...this.formData?.names?.[i],
                                    fNameInput: name[i]?.first_name,
                                    mNameInput: name[i]?.middle_name,
                                    lNameInput: name[i]?.last_name,
                                };
                            }
                        }
                        if (location) {
                            for (const i in location) {
                                this.formData.locations[i] = {
                                    ...this.formData?.locations?.[i],
                                    countryCodeInput: location[i]?.country,
                                    stateCodeInput: location[i]?.state,
                                    cityInput: location[i]?.city,
                                };
                            }
                        }
                        if (social_details) {
                            for (const i in social_details) {
                                this.formData.socialProfiles[i] = {
                                    ...this.formData?.socialProfiles?.[i],
                                    usernameInput: social_details[i]?.username,
                                    platformInput: social_details[i]?.social_profile || "",
                                };
                                this.selectedItem["username"][i] = this.getTag(social_details[i].username);
                                this.selectedItem["platform"][i] = this.getTag(social_details[i].social_profile);
                            }
                        }
                        if (educations) {
                            for (const i in educations) {
                                this.formData.educations[i] = {
                                    ...this.formData?.educations?.[i],
                                    degreeInput: educations[i].degree,
                                    schoolInput: educations[i].school,
                                    fromDateInput: educations[i].date_range.start_date,
                                    toDateInput: educations[i].date_range.end_date,
                                };
                                this.selectedItem["education"][i] = this.getTag(educations[i].school);
                            }
                        }
                        if (jobs) {
                            for (const i in jobs) {
                                this.formData.employments[i] = {
                                    ...this.formData?.employments?.[i],
                                    titleInput: jobs[i].title,
                                    organisationInput: jobs[i].organization,
                                    industryInput: jobs[i].industry,
                                    fromDateInput: jobs[i].date_range.start_date,
                                    toDateInput: jobs[i].date_range.end_date,
                                };
                                this.selectedItem["employment"][i] = this.getTag(jobs[i].organization);
                            }
                        }
                    }
                } else {
                    const {email, phone, first_name, last_name, middle_name, country, state, city, username, age} = selected_query;
                    if (email || phone || first_name || last_name || middle_name || country || state || city || username || age) {
                        if (email) {
                            this.formData.contacts[0].emailInput = [];
                            this.formData.contacts[0].emailInput.push(email);
                            this.selectedItem["email"][0] = this.getTag(email);
                        }
                        if (phone) {
                            this.formData.contacts[0].phoneInput = [];
                            this.formData.contacts[0].phoneInput.push(phone);
                            this.selectedItem["phone"][0] = this.getTag(phone);
                        }
                        if (username) {
                            this.formData.socialProfiles[0].usernameInput = username;
                            this.selectedItem["username"][0] = this.getTag(username);
                        }
                        this.formData.names[0].fNameInput = first_name ?? "";
                        this.formData.names[0].lNameInput = last_name ?? "";
                        this.formData.names[0].mNameInput = middle_name ?? "";
                        this.formData.locations[0].countryCodeInput = country ?? "";
                        this.formData.locations[0].stateCodeInput = state ?? "";
                        this.formData.locations[0].cityInput = city ?? "";
                    }
                }
                if (!dataRes) this.handleSearch();
            } else {
                this.redirectTo();
            }
        },
        handleExpand(piData, pi_index) {
            piData.expanded = !piData.expanded;
            this.$forceUpdate();
        },
        handle_more_content(result) {
            const index = result.index;
            this.showMoreInfo = true;
            this.selected_value = result;
            this.selected_index = index;
            setTimeout(() => {
                var target = document.getElementById(`result-card-${index}`);
                document.getElementById("result-list-data").scrollTop = target.offsetTop - 300;
                document.querySelector(".result-expanded").scrollTo(0, 0);
            }, 100);
        },
        closeMoreInfoExpand() {
            this.showMoreInfo = false;
            this.selected_value = {};
            this.selected_index = null;
        },
        backToSearch() {
            this.showMoreInfo = false;
            this.selected_value = {};
            this.selected_index = null;
            this.request_id = null;
            this.redirectTo();
        },

        handleClear() {
            this.formData = {
                names: [
                    {
                        fNameInput: "",
                        lNameInput: "",
                        mNameInput: "",
                        expanded: false,
                    },
                ],
                locations: [
                    {
                        countryCodeInput: "",
                        stateCodeInput: "",
                        cityInput: "",
                        expanded: false,
                    },
                ],
                contacts: [
                    {
                        emailInput: [],
                        phoneInput: [],
                        expanded: false,
                    },
                ],
                employments: [
                    {
                        titleInput: "",
                        organisationInput: "",
                        industryInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: false,
                    },
                ],
                educations: [
                    {
                        degreeInput: "",
                        schoolInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: false,
                    },
                ],
                socialProfiles: [
                    {
                        platformInput: "",
                        usernameInput: "",
                        expanded: false,
                    },
                ],
            };
            this.selectedItem = {
                email: [],
                phone: [],
                employment: [],
                education: [],
                platform: [],
                username: [],
            };
            this.csvData = {
                person: [],
            };
            this.queriesSections = [
                {
                    label: "Name",
                    value: "names",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Contact",
                    value: "contacts",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Employment",
                    value: "employments",
                    count: 1,
                    maxLimit: 2,
                },
                {
                    label: "Education",
                    value: "educations",
                    count: 1,
                    maxLimit: 2,
                },
                {
                    label: "Location",
                    value: "locations",
                    count: 1,
                    maxLimit: 1,
                },
                {
                    label: "Social Detail",
                    value: "socialProfiles",
                    count: 1,
                    maxLimit: 2,
                },
            ];
            this.allCategoriesExpanded = false;
            this.triggerRoute = false;
            this.$router.push({path: this.$route.path, params: {toolName: "person-identifier-tool"}});
        },

        /// MARK ALL AS DONE
        async handleMarkAsAllDone(event) {
            this.allData.forEach((result, index) => {
                this.allData[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            await axios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: ["person-identifier-tool"],
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },

        runToolsProfiles() {
            const contact = this.formData.contacts?.[0];
            for (const index in contact?.emailInput) this.setSearchedUser(contact?.emailInput[index], "email", "email");
            for (const index in contact?.phoneInput) this.setSearchedUser(contact?.phoneInput[index], "phone", "phone");
            for (const index in this.formData.socialProfiles) this.setSearchedUser(this.formData.socialProfiles[index].usernameInput, "username", "username");
            for (const index in this.formData.employments) this.setSearchedUser(this.formData.employments[index].organisationInput, "employment", "employment");
            for (const index in this.formData.educations) this.setSearchedUser(this.formData.educations[index].schoolInput, "education", "education");
        },

        async setSearchedUser(searchKeyword, type, val_type) {
            if (searchKeyword) {
                const data = {
                    value: searchKeyword,
                    platform: "person-identifier",
                    val_type: val_type || type,
                    visited: true,
                };
                let url = `research-methodology/${this.case_id}/tools/profiles`;
                let isSuccess = await setUsernamesDropdownData(url, data);
                let dataItem = this[`${type}List`].find((dataItem) => {
                    return dataItem.value === searchKeyword;
                });
                if (dataItem) dataItem["visited"] = true;
                else if (data?.value) {
                    this[`${type}List`].push(data);
                    this.$emit("addCustomData", data);
                }
                this.handleChecked(data);
            }
        },

        onInput(event, section, field, i) {
            if (section.value === "contacts") this.formData[section.value][i][field] = event?.map((el) => el.value) || [];
        },

        onSelect(event, section, field, i) {
            if (section.value === "contacts") this.formData[section.value][i][field].push(event.value);
            else this.formData[section.value][i][field] = event.value || "";
        },

        addTag(event, section, field, i, itemField) {
            const tag = this.getTag(event);
            if (section.value === "contacts") {
                this.formData[section.value][i][field].push(event);
                let data = this.selectedItem[itemField];
                data.push(tag);
                this.$set(this.selectedItem, itemField, data);
            } else {
                this.formData[section.value][i][field] = event || "";
                let data = this.selectedItem[itemField];
                data[i] = tag;
                this.$set(this.selectedItem, itemField, data);
            }
            this[`${itemField}List`].push(tag);
            this.$forceUpdate();
        },

        removeTag(removedOption, section, field, i, itemField) {
            if (section.value === "contacts") {
                let match = -1;
                match = this.formData[section.value][i][field].findIndex((item) => {
                    return item === removedOption.value;
                });
                if (match > -1) this.formData[section.value][i][field].splice(match, 1);

                let itemMatch = -1;
                itemMatch = this.selectedItem[itemField].findIndex((item) => {
                    return item.value === removedOption.value;
                });
                if (itemMatch > -1) {
                    let data = this.selectedItem[itemField];
                    data.splice(itemMatch, 1);
                    this.$set(this.selectedItem, itemField, data);
                }
            } else {
                this.formData[section.value][i][field] = "";
                let data = this.selectedItem[itemField];
                data[i] = "";
                this.$set(this.selectedItem, itemField, data);
            }
        },

        getTag(tag) {
            return {
                name: tag,
                value: tag,
            };
        },

        toggleAllCategories() {
            this.queriesSections.forEach((section) => {
                this.formData[section.value] = this.formData[section.value].map((field) => ({
                    ...field,
                    expanded: !this.allCategoriesExpanded,
                }));
            });
            this.allCategoriesExpanded = !this.allCategoriesExpanded;
        },

        addField(section) {
            if (section.count === section.maxLimit) this.$toast.error(`Only ${section.maxLimit} ${section.label.toLowerCase()}s are allowed.`);
            else if (section.count < section.maxLimit) {
                section.count += 1;
                if (section.value === "employments")
                    this.formData[section.value].push({
                        titleInput: "",
                        organisationInput: "",
                        industryInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: true,
                    });
                else if (section.value === "educations")
                    this.formData[section.value].push({
                        degreeInput: "",
                        schoolInput: "",
                        fromDateInput: "",
                        toDateInput: "",
                        expanded: true,
                    });
                else if (section.value === "contacts")
                    this.formData[section.value].push({
                        emailInput: [],
                        phoneInput: [],
                        expanded: true,
                    });
                else if (section.value === "socialProfiles")
                    this.formData[section.value].push({
                        platformInput: "",
                        usernameInput: "",
                        expanded: true,
                    });
            }
        },

        deleteField(section, i) {
            if (section.count === 1) this.$toast.error(`Minimum 1 field is required.`);
            else if (section.count > 1) {
                section.count -= 1;
                this.formData[section.value].splice(i, 1);
            }
        },
    },
};
